
.logo{
    
    text-align: center!important;
    width: 68px;
    height: 68px;
    border-radius: 50%!important;
    border-style: solid;
    border-width: 2px!important;
    border-color:chartreuse!important;
    background-color: white!important;
    animation:  logorotate 10s infinite linear; 
}


.adjustName{
    text-align: center;
    width: 80px;
    height: 90px;
    
}

.overlay{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    padding: 10px 20px;
    color: black!important;
    background-color: black;
    opacity: 0.8;
    overflow: hidden;
    transition: 0.3s;
    
   
}

.card:hover .overlay {
    height: 100%;
    
}

.gap{
    margin-left: 6px!important;
}

.gapIt{
    margin-right: 8px!important;
}

.overlayNew{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0;
    padding: 10px 20px;
    color: black!important;
    background-color: white;
    opacity: 0.8;
    overflow: hidden;
    transition: 0.3s;
    
   
}

.card:hover .overlayNew {
    height: 100%;
    
}

.FooterBgC{
    background-color: black;
    /* opacity: 0.9!important; */
    height: 150px!important;
    width: 100% !important;
}

.card{
    float: left!important;
    box-shadow: 10px 15px 20px rgb(45, 49, 51)!important;
    height: 400px !important;
    width: 500px !important;
    margin-top: 2%!important;
    margin-left: 5%!important;
    margin-bottom: 2%!important;

}

.mobilecard{
        
   
    height: 200px !important;
    width: 50% !important;
    /* margin-top: 2%!important; */
    margin-left: -2px!important;
    margin-bottom: 2%!important;

}

.text{
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: black;
    margin-top: 20px!important;
    margin-bottom: 2px;
    font-weight: bold;
}

.card-img-top{
    width: 100%!important;
    height: 280px !important;
   
}

.note {
    transform: translate(0, 0);
    /* margin-top: 10px; */
    font-family: Georgia, 'Times New Roman', Times, serif;
    color:gold
}


.fontopt1{
    color:chartreuse;
    margin-right: 75px !important;
    font-size: 18px!important;
    
}
.fontopt1:hover{
    color: tomato;
}

.fontopt3:hover{
    color: tomato;
}

.fontopt2{
    color:chartreuse;
    margin-right: 55px!important;
    font-size: 18px!important;
}

.fontoptphone{
    color:silver;
    position:absolute;
    right: 90px;
    top:120px;
    
}

.fontopt3{
    color:chartreuse;
    margin-right: 55px!important;
    font-size: 18px!important;
}

.fontopt2:hover{
    color: tomato;
}

.fontoptphone:hover{
    color: tomato;
    
}

.fontoptphone{
    color:silver;
    position:absolute;
    right: 90px;
    top:120px;
    
}

.logoutcolor{
    color:silver;
}

@keyframes logorotate{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.reshape{
    border-radius: 50% !important;
    background-color:black;
    margin-left: 8px!important;
    width:60px;
    height:60px;
}

.twitr{
    display: inline-block!important;
    margin-top: 10px!important;
    
}

.headerbackgrd{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95px !important;

}

.headerbackgrd3{
    background-color:black;
    height: 100%;
    

}

.carouselBx3{
    font-size:40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.carouselBx4{
    font-size:20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.menu{
    width: 100%;
    margin-Top:60px;
    background-color:black;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.CartSettings{
    position:relative;
}

.Cartmenu{
    width: 100%;
    margin-top:80px;
    height:30px;
    background-color:black;
    position: relative;
    
}

.menulist{
    color:silver;
    text-decoration: none!important;
    font-size: 25px !important;
    
    
}

.menulistNow{
    color:silver;
    text-decoration: none!important;
    font-size: 18px !important;
    
    
    
}

.relatOrd{
    position:absolute;
    left:3px;
    top:-10px;
}




.menulist:hover{
    color:tomato;
}

.headerbackgrd4{
    display:flex;
    justify-content:space-evenly;
    align-items:flex-start;
    height: auto;
    width:100%;
    background-color:black;
    
}



.fitIn{
    margin-top: 90px;

}
.carddiv{
    max-height:250px;
    width:auto;
    background-color:black;
    flex-flow: row wrap;
   
    
}

.titletxt{
    font-weight: bold;
    font-size:18px;
}

.divimg{
    height:200px;
    width:290px;
}
.alignbody{
    margin-top: 10px;
}

.titlestyle{
    margin-top: 25%;
    color:silver;
}


.headerbackgrd2{
    background-color:rgb(58, 57, 59);
    margin-top: 1px!important;
    margin-bottom: 1px!important;
    height: 80px!important;
    display: flex;
    align-items: center;
    justify-content: center;
   
}

.facebk{
    display: inline-block!important;
}

.insta{
    display: inline-block!important;
}

.addrs{
    display: inline-block!important;
}

.navtext{
    text-decoration: none!important;
    color: rgb(236, 233, 233)!important;
    
}
.buttonformat{
    background-color: rgb(238, 226, 160);
    
    
}
.bg-dark{
    background-color: black!important;
}
.bookbtn{
    width: 100px;
    background-color: rgb(238, 226, 160);
    color: black;
    margin-left: 20px;
    height: 35px;
    text-decoration: none!important;

}
.bookbtn2{
    text-decoration: none!important;
    color: black;
}
.alignmenu{
    margin-left: 484px;
}

.formatcolor{
    background-color:black;
    width:100%;
}

.size{
    font-size: 30px;
    color: silver !important;
}

.size2{
    font-size: 30px!important;
    color: silver !important;
    position:absolute;
    left:60px;
    top:39px;
}

.imgsize{
    height:auto;
}

.carouselBx{
    display:flex;
    position:relative;
    align-items: center;
    justify-content: center;
}

.carouselBx2{
    position:absolute;
    bottom:333px;
    left:241px;
   

}



.size3{
    font-size: 20px!important;
    position:absolute;
    right:30px;
    top:15px;
}

.size4{
    font-size: 20px!important;
    position:absolute;
    left:30px;
    top:35px;
    color: silver !important;
}

.size4b{
    font-size: 16px!important;
    position:absolute;
    left:30px;
    top:55px;
    color: silver !important;
}

.size4:hover{
    color: tomato !important;
}
.size5{
    font-size: 20px!important;
    position:absolute;
    left:70px;
    top:35px;
    color: silver !important;
}
.size5:hover{
    color: tomato !important;
}
.envelopefrmt{
    color:rgb(236, 233, 233);
}

.shift{
    padding: 30px!important;
}   

.size:hover{
    color: tomato !important;
}

.size2:hover{
    color: tomato !important;
}



.formttxt{
    color: rgb(83, 82, 82);
    font-size: 60px;
    margin-top: 40px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-bottom: 20px;
}

.formttxt2{
    color: rgb(83, 82, 82);
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 30px;
}

.Ikem{
    position: absolute;
    right: 80px !important;
    font-size: 20px!important;
    color:silver;
    

}
.Ikem:hover{
    color: tomato !important;
}

.Adriel{
    position: absolute;
    right: 50px !important;
    font-size: 20px!important;
    color:silver;

}

.AdrielChizaram{
    position: absolute;
    right: 80px !important;
    top: 40px !important;
    font-size: 10px!important;
    color:white;
    background-color: red;
    border-radius: 50%;
    height: 15px;
    width: 15px;
   

}

.Visible{
    visibility : visible!important;
}

.hidden{
    visibility : hidden!important; 
}

.Adriel:hover{
    color: tomato !important;
}

.sizeOfImage{
    width: 350px !important;
    height: 350px !important;
    border-radius: 2%!important;
    position:relative;
    
    
}

.placePrice{
    position:absolute;
}

.placePrice2{
    position:absolute;
    left:12px;
    
}

.makesuperscript{
    font-weight: bold;
    font-size: 12px;
    vertical-align: super;
    
}

.addColor{
    background-color: black;
}

.mobileViewAbs{
    color: white!important;
   
   
}

.CartImageSize{
    width: 30px!important;
    height:30px!important;
}

.btnHeight{
    width:250px;
    margin-top:20px;
    border-radius: 20px;
    font-weight: bold;
       
}

.alignCartText{
    color:white;
    font-size: 10px!important;
    margin-left: 10px!important;
    

}

.tryDis{
    margin-top: -20px!important;
    max-height: 300px; 
    overflow: auto;
    
   
}
.tryDis::-webkit-scrollbar {
    width: 12px; /* Width of the vertical scrollbar */
}

.tryDis::-webkit-scrollbar-thumb {
    background-color: grey; /* Color of the scrollbar thumb */
    border-radius: 6px; /* Roundness of the thumb */
}

.tryDis::-webkit-scrollbar-track {
    background-color: yellow; /* Color of the scrollbar track */
    border-radius: 6px; /* Roundness of the track */
  }

.cartTotalFormt{
    color:white;
    font-size: 12px!important;
    
    
}

.cartTotalFMT{
    color:yellow;
    font-size: 14px!important;
    font-weight: bold;
    
}

.CartNumberForMaT{
    color:yellow;
    font-size: 14px!important;
    font-weight: bold;
    text-align: right!important;
    margin-top: -20px;
    
}

.CartNumberFMT{
    color:white;
    font-size: 12px!important;
    text-align: right;
    margin-top: -20px;
   
}

.CartmobileViewAbs{
    color: white!important;
}


/* .placeNicely{
    margin-top: 50px!important;
} */


@media (max-width: 767px) {
    .carouselBx2{
        position:absolute;
        bottom:16px;
        left:26px;
       
    }

    .mobileViewRel{
        position: relative;
    }

    .mobileViewAbs{
        position: absolute;
        top: 100px!important;
        color: white!important;
        font-size: 18px;
       
    }

    .CartmobileViewAbs{
        position: absolute;
        top: 100px!important;
        color: white!important;
        font-size: 18px;
       
    }

    .putinplace{
        position: relative;
    }

    .text{
        font-family: Georgia, 'Times New Roman', Times, serif;
        color: black;
        margin-top: 20px!important;
        font-weight: bold;
    }

    .AdrielChizaram{
        position: absolute;
        right: 4px !important;
        top: 10px !important;
        font-size: 10px!important;
        color:white;
        background-color: red;
        border-radius: 50%;
        height: 15px;
        width: 15px;
       
    
    }

    

    .fitIn{
        /* position: absolute!important; */
        margin-top: -580px!important;
    
    }

    .placeNicely{
        margin-top: -2px!important;
    }

    .card-title{
        margin-top: -100px!important;
    }

    
    .sizeOfImage{
        
        border-radius: 2%!important;
        position:relative;
        
    }

    .card-img-top{
        
        width: 100%!important;
        height: 240px!important;
        
    }

    .headerbackgrd{
        background-color:black;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px !important;
    
    }


    .reshape{
        border-radius: 50% !important;
        background-color:black;
        margin-left: 8px!important;
        width:42px;
        height:42px;
    }

    .carouselBx3{
        font-size:30px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .formttxt{
        color: rgb(83, 82, 82);
        font-size: 40px;
        margin-top: 40px;
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        margin-bottom: 20px;
    }

    .size2{
        font-size: 30px!important;
        color: silver !important;
        position:absolute;
        left:5px;
        top:19.5px;
    }

    .size2:hover{
        color: tomato !important;
    }

    .menulist:hover{
        color:tomato;
    }

    .size:hover{
        color: tomato !important;
    }

    .headerbackgrd4{
        display:flex;
        justify-content:center;
        align-items:stretch;
        width:100%;
        background-color:black;
        position:relative;
        
    }
    
    .carddiv{
       
        width: 200px !important;
        background-color:black;
        flex-flow: row wrap;
        
    }

    .titletxt{
        font-weight: bold;
        font-size:13px;
        margin-top:40px;
    }

    .measure{
        width:100%
    }

    .divimg{
        height:100px;
        width:100px;
    }

    .bodytxt{
        display:none;
    }

    .titlestyle{
       
        font-size: 20px;
        color:silver;
        margin-top:10px;
    }

    .menulist{
        color:silver;
        text-decoration: none!important;
        font-size: 18px !important;
        
        
    }

    .Ikem{
        position: absolute;
        right: 20px !important;
        top:12px !important;
        font-size: 18px!important;
        color:silver;
        
    
    }
    
    .Ikem:hover{
        color: tomato !important;
    }
    
    .Adriel{
        position: absolute;
        right: 8px !important;
        top:12px !important;
        font-size: 18px!important;
        color:silver;
        
    
    }

    .Adriel:hover{
        color: tomato !important;
    }

    .logo{
        width: 75px;
        height: 75px;
        border-radius: 50%!important;
        border-style: solid;
        border-width: 2px!important;
        border-color:chartreuse!important;
        background-color: white!important;
        animation:  logorotate 10s infinite linear; 
    }

    .adjustName{
        position: absolute;
        left: 155px !important;
        top: 12px !important;
        width: 90px;
        height: 80px!important;
    }

    .logo{
        position: absolute;
        left: 190px !important;
        top: 8px !important;
        width: 48px;
        height: 48px;
        border-radius: 50%!important;
        border-style: solid;
        border-width: 2px!important;
        border-color:chartreuse!important;
        background-color: white!important;
        animation:  logorotate 10s infinite linear; 
    }

    .fontopt1{
        color:chartreuse;
        margin-right: 18px !important;
        font-size: 14px!important;
    }
    .fontopt1:hover{
        color: tomato;
    }
    
    .fontopt3:hover{
        color: tomato;
    }
    
    .fontopt2{
        color:chartreuse;
        margin-right: 15px!important;
        font-size: 14px!important;
    }

    .fontoptphone{
        visibility: hidden;
        
    }   
    
    .fontopt3{
        color:chartreuse;
        margin-right: 15px!important;
        font-size: 14px!important;
    }
    
    .fontopt2:hover{
        color: tomato;
    }

    .card{
        
        box-shadow: 10px 15px 20px rgb(45, 49, 51)!important;
        height: 300px !important;
        width: 100% !important;
        /* margin-top: 2%!important; */
        margin-left: -2px!important;
        margin-bottom: 2%!important;
    
    }

    .mobilecard{
        
        box-shadow: 10px 15px 20px rgb(45, 49, 51)!important;
        height: 300px !important;
        width: 100% !important;
        /* margin-top: 2%!important; */
        margin-left: -2px!important;
        margin-bottom: 2%!important;
    
    }
        
}

/* @media (min-width: 768px) and (max-width: 1023px) {
    .logo{
        position: absolute !important;
        left: 395px !important;
        top: 5px !important;
        width: 52px;
        height: 52px;
        border-radius: 50%!important;
        border-style: solid;
        border-width: 2px!important;
        border-color:chartreuse!important;
        background-color: white!important;
        animation:  logorotate 10s infinite linear; 
    }

    .headerbackgrd{
        background-color:rgb(124, 163, 85);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px !important;
    
    }

    .adjustName{
        position: absolute !important;
        right: 30px !important;
        top: 20px !important;
        width: 180px;
        height: 90px!important;
    }

    .carouselBx2{
        position:absolute;
        bottom:16px;
        left:26px;
       
    }

    .reshape{
        border-radius: 50% !important;
        background-color:black;
        margin-left: 8px!important;
        width:60px;
        height:60px;
    }

    .size2{
        font-size: 20px!important;
        color: silver !important;
        position:absolute;
        left:15px;
        top:23px!important;
    }

    .size2:hover{
        color: tomato !important;
    }

    .menulist:hover{
        color:tomato;
    }

    .size:hover{
        color: tomato !important;
    }

    .menulist{
        color:silver;
        text-decoration: none!important;
        font-size: 18px !important;
        
        
    }

    .Ikem{
        position: absolute;
        right: 30px !important;
        top:12px !important;
        font-size: 18px!important;
        color:silver;
       
    
    }

    .Ikem:hover{
        color: tomato !important;
    }
    
    .Adriel{
        position: absolute;
        right: 5px !important;
        top:12px !important;
        color:silver;
        
    
    }

    .Adriel:hover{
        color: tomato !important;
    }
    
} */


